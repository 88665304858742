import {
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputTextField,
  useWindowSize,
} from "@flashparking-inc/ux-lib-react";
import { FormEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";

interface MfaVerificationFormProps {
  doMfaLogin: FormEventHandler;
  errors?: { service?: string };
  goBack: () => void;
  phoneNumber?: string;
  resendCode?: () => Promise<void>;
  disableResend: boolean;
  isResending: boolean;
}

export default function LoginMfaVerificationForm({
  doMfaLogin,
  errors,
  goBack,
  phoneNumber,
  disableResend,
  resendCode,
  isResending,
}: MfaVerificationFormProps) {
  const { t } = useTranslation();
  const { windowIsMobile } = useWindowSize();

  const [codeLengthValid, setCodeLengthValid] = useState(false);

  const validationMessages: FlashFormFieldValidationMessageProps[] = errors?.service
    ? [
        {
          type: "invalid",
          message: errors.service,
        },
      ]
    : [];

  function onCodeChange(value?: string) {
    if (value?.length === 6) {
      setCodeLengthValid(true);
    } else {
      setCodeLengthValid(false);
    }
  }

  return (
    <div className="d-flex flex-column gap-flash-200 mb-flash-300">
      <header className="flash-font-heading-xl">{t("LOGIN:ENTER_VER_CODE")}</header>
      <p className="my-0">
        {t("LOGIN:ENTER_MFA_CODE_DESC")}
        <span className="ms-flash-050 fw-bold">{phoneNumber?.slice(phoneNumber.length - 4)}</span>
      </p>
      {!!resendCode && (
        <FlashButton
          id="resend-mfa-code-btn"
          disabled={disableResend}
          color="link"
          onClick={resendCode}
          isLoading={isResending}
        >
          {t("LOGIN:RESEND_CODE")}
        </FlashButton>
      )}
      <form onSubmit={doMfaLogin}>
        <div className="d-flex flex-column gap-flash-200">
          <FlashFormInputTextField
            id="mfa_code"
            label={{
              text: t("LOGIN:ENTER_VER_CODE"),
              helperText: t("LOGIN:VER_CODE_EXPIRY_WARNING"),
            }}
            validationMessages={validationMessages}
            onChange={onCodeChange}
            type="number"
            autoFocus
          />
          <FlashButton id="next-btn" type="submit" disabled={!codeLengthValid} isBlock>
            {t("LOGIN:LOGIN")}
          </FlashButton>
          <div className="text-center">
            <FlashButton
              id="back-to-login-btn"
              onClick={goBack}
              color="link"
              isBlock={windowIsMobile}
            >
              {t("LOGIN:BACK_TO_LOGIN")}
            </FlashButton>
          </div>
        </div>
      </form>
    </div>
  );
}
