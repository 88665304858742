import apiErrorCodeEnum from "lib/services/apiErrorCodeEnum";

const generateRegistrationErrorMessage = (errorCode, details, message, t) => {
  let errorMessage = "";
  switch (errorCode) {
    case apiErrorCodeEnum.not_authorized:
      errorMessage = t("LOGIN:INVALID_TOKEN");
      break;
    case apiErrorCodeEnum.user_alias_exists:
      errorMessage = details?.username
        ? t("LOGIN:ERROR_USER_ALREADY_EXISTS_W_NAME", { username: details.username })
        : t("LOGIN:ERROR_USER_ALREADY_EXISTS");
      break;
    default:
      errorMessage = t("LOGIN:UNKNOWN");
      break;
  }
  return errorMessage;
};

export default generateRegistrationErrorMessage;