import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { AuthService, authResponseCodeEnum } from "lib/services/auth";
import { useAuthentication } from "lib/context/AuthenticationContext";
import rollbar from "../../../lib/vendor/rollbar/rollbarService";

import generateErrorMessage from "../utils/generateErrorMessage";
import LoginMfaVerificationForm from "../forms/LoginMfaVerificationForm";
import BasePage from "lib/components/presentation/BasePage";
import { FlashInfoMessageType, useToasts } from "@flashparking-inc/ux-lib-react";
import {
  getQueryStringParamsFromHistory,
  handleAuthChallengeResponse,
} from "lib/services/auth/handleAuthChallengeResponse";
import { useCodeResender } from "../utils/useCodeResender";
import { ApiError } from "lib/services/apiClient";

const LoginMfaVerificationPage = () => {
  const { challengeResult, setAuthChallengeResult } = useAuthentication();
  const { t } = useTranslation();
  const history = useHistory();
  const { addToast } = useToasts();

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ service?: string }>();

  const isForcedMfaSetup = challengeResult?.challenge?.forcedMfaSetup;
  const phoneNumberFromAuthChallenge = challengeResult?.parameters?.code?.destination;
  const { resendDisabled, setResendDisabled, isResending, setIsResending, beginResendCountdown } =
    useCodeResender({ preventDisable: !isForcedMfaSetup });

  const doMfaLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const mfaCode = data.get("mfa_code");

    setErrors(undefined);

    // verify
    let verified = false;
    if (!mfaCode || (mfaCode as string).length !== 6) {
      setErrors({ service: t("LOGIN:ENTER_VALID_CODE") });
    } else {
      verified = true;
    }

    if (verified) {
      setSubmitting(true);
      try {
        const mfaChallengeResult = await AuthService.smsMfaChallenge(
          challengeResult?.challenge,
          mfaCode
        );
        await handleAuthChallengeResponse({
          history,
          setAuthChallengeResult,
          challengeResult: mfaChallengeResult,
        });
      } catch (error) {
        rollbar.error(error as Error);
        if ((error as ApiError).message === "too_many_requests") {
          history.replace({
            state: {
              ...(history.location.state || {}),
              errorBanner: {
                title: t("LOGIN:TOO_MANY_FAILED"),
                description: t("LOGIN:TOO_MANY_FAILED_CODE_DETAIL"),
              },
            },
          });
        } else if ((error as ApiError).errorCode === "code_mismatch") {
          const errorMessage = generateErrorMessage(
            (error as ApiError).errorCode,
            (error as ApiError).details,
            (error as ApiError).message,
            t
          );
          setErrors({ service: errorMessage });
        } else if (
          (error as ApiError).message.includes("session is expired") ||
          (error as ApiError).message.includes("Invalid Session")
        ) {
          setErrors({ service: t("LOGIN:ERROR_EXPIRED_SESSION") });
        }
        // TODO: do we need a default handler here?
      }
      setSubmitting(false);
    }
  };

  async function resendCode() {
    setErrors(undefined);
    setResendDisabled(true);
    setIsResending(true);
    try {
      const mfaRequiredChallengeResult = await AuthService.mfaRequiredChallenge(
        challengeResult?.challenge,
        {
          phoneNumber: getQueryStringParamsFromHistory(history).phoneNumber,
        }
      );
      await handleAuthChallengeResponse({
        history,
        setAuthChallengeResult: setAuthChallengeResult,
        challengeResult: mfaRequiredChallengeResult,
        preventRedirect: { [authResponseCodeEnum.mfa_setup_required]: true },
      });
      addToast({
        props: {
          type: FlashInfoMessageType.success,
          title: t("LOGIN:CODE_SENT"),
        },
      });
      beginResendCountdown();
    } catch (error) {
      rollbar.error(error as Error);
      addToast({
        props: {
          type: FlashInfoMessageType.critical,
          title: t("LOGIN:ERROR_CODE_SEND_FAILED"),
        },
      });
      const errorMessage = generateErrorMessage(
        (error as ApiError).errorCode,
        (error as ApiError).details,
        (error as ApiError).message,
        t
      );
      setErrors({ service: errorMessage });
      setResendDisabled(false);
    }
    setIsResending(false);
  }

  const pageContent = (
    <LoginMfaVerificationForm
      doMfaLogin={doMfaLogin}
      errors={errors}
      goBack={() => {
        history.replace("/login");
      }}
      phoneNumber={phoneNumberFromAuthChallenge}
      disableResend={resendDisabled}
      // for now we don't show resend code button if they're in the regular login flow
      resendCode={isForcedMfaSetup ? resendCode : undefined}
      isResending={isResending}
    />
  );

  return challengeResult && phoneNumberFromAuthChallenge ? (
    <BasePage pageContent={pageContent} isLoading={submitting} showSupportLink={false} />
  ) : (
    <Redirect to="/login" />
  );
};

export default LoginMfaVerificationPage;
