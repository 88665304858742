import {
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputPassword,
  FlashInfoMessageType,
  invokeFnSync,
  useToasts,
} from "@flashparking-inc/ux-lib-react";
import { FormEventHandler, Fragment, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { AuthService } from "lib/services/auth";
import { getQueryStringParamsFromHistory } from "lib/services/auth/handleAuthChallengeResponse";
import { useCodeResender } from "modules/login/utils/useCodeResender";

import CodeInput from "../forms/CodeInput";
import { addOrReplaceQueryStringValue } from "lib/utils/http";
import { ApiError } from "lib/services/apiClient";
import apiErrorCodeEnum from "lib/services/apiErrorCodeEnum";
import { passwordValidationMessageType, validatePasswordUsingPolicy } from "lib/utils/passwords/validatePasswordUsingPolicy";
import { IAMAccessPolicy } from "lib/utils/passwords/accountPolicy";
import { useAuthentication } from "lib/context/AuthenticationContext";

export type NewPasswordEntryProps = {
  /** Callback to invoke when user submits new password data */
  onSubmit?: FormEventHandler<HTMLFormElement>;
  /** Error message to relay to the user */
  errorMessage?: string;
  /** Error thrown during submission of form */
  submissionError?: ApiError;
  /** Function to clear the current submission error value */
  clearSubmissionError?: () => void
  /** Whether or not the verification code input should be shown */
  showCodeInput?: boolean;
  /**
   * Message to render above the form, e.g. to let user know a code has been sent
   *
   * @default null
   */
  Notice?: ReactNode | null;
};

export default function NewPasswordEntry(props: NewPasswordEntryProps) {
  const { onSubmit, errorMessage, submissionError, clearSubmissionError, showCodeInput, Notice = null } = props;

  const history = useHistory();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { challengeResult } = useAuthentication();

  const params = getQueryStringParamsFromHistory(history);

  const [accessPolicy, setAccessPolicy] = useState<Partial<IAMAccessPolicy>>()
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [code, setCode] = useState("");
  const [codeTouched, setCodeTouched] = useState(false);
  const { resendDisabled, setResendDisabled, isResending, setIsResending, beginResendCountdown } =
    useCodeResender({ defaultDisabled: false });

  const [rules, setRules] = useState<{
    code: FlashFormFieldValidationMessageProps[];
    password: FlashFormFieldValidationMessageProps[];
    passwordConfirm: FlashFormFieldValidationMessageProps[];
  }>({
    code: [],
    password: validatePasswordUsingPolicy({ t, policy: accessPolicy, password: '' }),
    passwordConfirm: [
      {
        message: t("LOGIN:RULES_MATCH"),
        type: "requirement",
      },
    ],
  });

  const updateCode = (value = "") => {
    invokeFnSync(clearSubmissionError);
    if (code.length && params["code"]) {
      history.replace({ search: addOrReplaceQueryStringValue("code", "") });
    }
    setCode(value);
  };

  const updatePassword = (value = "") => {
    invokeFnSync(clearSubmissionError);
    setPassword(value);
  };

  const updatePasswordConfirm = (value = "") => {
    setConfirmPassword(value);
  };

  useEffect(
    function setNewPasswordAccessPolicy() {
      if (!challengeResult?.parameters?.policy && !submissionError?.details.complexity) {
        return;
      }

      if (submissionError?.details?.complexity) {
        setAccessPolicy({
          passwordComplexity: submissionError.details.complexity,
        });
        return;
      }

      setAccessPolicy(challengeResult?.parameters?.policy);
    },
    [challengeResult, submissionError]
  );

  useEffect(
    function updateValidationMessages() {
      const submissionErrorCode = (submissionError as ApiError | undefined)?.errorCode

      const codeRules: FlashFormFieldValidationMessageProps[] = [];
      if (submissionErrorCode === apiErrorCodeEnum.code_mismatch) {
        codeRules.push({ message: t("LOGIN:ERROR_CODE_MISMATCH"), type: "invalid" });
      }

      const passwordRules = validatePasswordUsingPolicy({ t, password, policy: accessPolicy })

      if (submissionErrorCode === apiErrorCodeEnum.recently_used_password) {
        passwordRules.unshift({
          message: t("LOGIN:RULES_RECENT_PASSWORD"),
          type: "invalid"
        })
      }

      const passwordConfirmRules: FlashFormFieldValidationMessageProps[] = [
        {
          message: t("LOGIN:RULES_MATCH"),
          type: passwordValidationMessageType(
            !!password?.length && !!confirmPassword?.length && password === confirmPassword
          ),
        },
      ];

      if (errorMessage) {
        passwordRules.unshift({ message: errorMessage, type: "invalid" });
      }

      setRules(() => ({
        code: codeRules,
        password: passwordRules,
        passwordConfirm: passwordConfirmRules,
      }));
    },
    [accessPolicy, submissionError, password, confirmPassword, code]
  );

  useEffect(
    function checkFormValidity() {
      const isValid =
        [...rules.password, ...rules.passwordConfirm, ...rules.code].filter(
          (rule) => rule.type !== "valid"
        ).length === 0;
      setPasswordIsValid(isValid);
    },
    [rules]
  );

  function validateCode(code?: string): FlashFormFieldValidationMessageProps[] {
    const messages: FlashFormFieldValidationMessageProps[] = [];
    // Code should be 6 numerical characters
    const codeIsSixDigits = code?.length === 6 && /^[0-9]+$/.test(code);

    // Wait until user has typed into and left the field to present error messaging
    if (!codeIsSixDigits && codeTouched) {
      messages.push({ type: "invalid", message: t("LOGIN:ENTER_VER_CODE") });
    }

    setRules((prev) => ({ ...prev, code: messages }));
    return messages;
  }

  async function resendCode() {
    // remove code from query params so user doesn't try to submit stale code
    const newParams = new URLSearchParams(history.location.search);
    newParams.delete("code");
    history.replace({
      search: newParams.toString(),
    });
    updateCode("");
    invokeFnSync(clearSubmissionError);

    setCodeTouched(false);
    setResendDisabled(true);
    setIsResending(true);

    try {
      await AuthService.forgotPassword(params.username, params.clientType);
      addToast({
        props: {
          type: FlashInfoMessageType.success,
          title: t("LOGIN:CODE_SENT"),
        },
      });
      beginResendCountdown();
    } catch (error) {
      addToast({
        props: {
          type: FlashInfoMessageType.critical,
          title: t("LOGIN:ERROR_CODE_SEND_FAILED"),
        },
      });
      setResendDisabled(false);
    }

    setIsResending(false);
  }

  return (
    <Fragment>
      <header className="flash-font-heading-xl">{t("LOGIN:CREATE_NEW_PASSWORD")}</header>
      {Notice}
      <form
        onSubmit={onSubmit}
        noValidate
        className="my-flash-300 d-flex flex-column gap-flash-300"
      >
        {showCodeInput && (
          <div className="d-flex flex-column gap-flash-100">
            <CodeInput
              idPrefix="new_password_"
              value={params["code"] || code}
              onChange={updateCode}
              onBlur={() => {
                setCodeTouched(true);
              }}
              validate={validateCode}
              codeTouched={codeTouched}
              validationMessages={rules.code}
            />
            <FlashButton
              id="new_password_resend_code_btn"
              color="link"
              onClick={resendCode}
              disabled={resendDisabled}
              isLoading={isResending}
            >
              {t("LOGIN:RESEND_CODE")}
            </FlashButton>
            <hr className="mt-flash-100 mb-0" />
          </div>
        )}
        <FlashFormInputPassword
          id="new_password"
          label={{ text: t("LOGIN:CREATE_PASSWORD") }}
          password={password}
          onChange={updatePassword}
          autoFocus={!!params["code"]}
          validationMessages={rules.password}
        />
        <FlashFormInputPassword
          id="confirm_password"
          label={{ text: t("LOGIN:CONFIRM_PASSWORD") }}
          password={confirmPassword}
          onChange={updatePasswordConfirm}
          validationMessages={rules.passwordConfirm}
        />
        <FlashButton
          id="new-password-entry-submit-btn"
          type="submit"
          color="primary"
          disabled={!passwordIsValid}
          isBlock
        >
          {t("COMMON:CONTINUE")}
        </FlashButton>
      </form>
    </Fragment>
  );
}

