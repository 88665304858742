import "./styles/style.scss";

// External
//toggle styles
import "react-toggle/style.css";
import { FlashToasts } from "@flashparking-inc/ux-lib-react";
import React from "react";
import { Route, Switch } from "react-router-dom";

// Local
import { useAuthentication } from "lib/context/AuthenticationContext";
import { getQueryStringParams } from "lib/utils/http";

// Pages
import LoadingPage from "lib/components/presentation/LoadingPage";
import RedirectPage from "modules/login/views/RedirectPage";
import LoginEnterEmailPage from "modules/login/views/LoginEnterEmailPage";
import LoginEnterPasswordPage from "modules/login/views/LoginEnterPasswordPage";
import LoginForgotPasswordPage from "modules/login/views/LoginForgotPasswordPage";
import VerifyEmailPage from "modules/login/views/VerifyEmailPage";
import ResetPasswordPage from "modules/login/views/ResetPasswordPage";
import NewPasswordRequiredPage from "modules/login/views/NewPasswordRequiredPage";
import LoginMfaVerificationPage from "modules/login/views/LoginMfaVerificationPage";
import ExternalProviderPage from "modules/login/views/ExternalProviderPage";
import LogoutPage from "modules/logout/LogoutPage";

import RegisterPage from "modules/login/views/RegisterPage";
import ErrorPage from "modules/login/views/ErrorPage";
import MfaPhoneNumberRequiredPage from "modules/login/views/MfaPhoneNumberRequiredPage";
import ChangePasswordPage from "modules/profile/views/ChangePasswordPage";
import {Redirect} from "react-router";

export default function App() {
  const { isAuthenticated } = useAuthentication();
  const params = getQueryStringParams();

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Switch>
        <Route
          path="/redirect"
          name="Redirect"
          render={(props) => <RedirectPage {...props} />}
        />
        <Route
          path="/login/password"
          name="Login with Password"
          render={(props) => <LoginEnterPasswordPage {...props} />}
        />
        <Route
          exact
          path="/login/mfa"
          name="MFA Verification"
          render={(props) => <LoginMfaVerificationPage {...props} />}
        />
        <Route
          exact
          path="/password/forgot"
          name="Forgot Password"
          render={(props) => <LoginForgotPasswordPage {...props} />}
        />
        <Route
          exact
          path="/verify/attribute"
          name="Verify Email"
          render={(props) => <VerifyEmailPage {...props} />}
        />
        <Route
          exact
          path="/password/reset"
          name="Reset Password"
          render={(props) => <ResetPasswordPage {...props} />}
        />
        <Route
          exact
          path="/password/required"
          name="New Password Required"
          render={(props) => <NewPasswordRequiredPage {...props} />}
        />
        <Route
          exact
          path="/mfa/required"
          name="MFA Phone Number Required"
          render={(props) => <MfaPhoneNumberRequiredPage {...props} />}
        />
        <Route
          exact
          path="/profile/change-password"
          name="Change Password"
          render={(props) => <ChangePasswordPage {...props} />}
        />
        <Route
          exact
          path="/provider"
          name="External Identity Provider"
          render={(props) => <ExternalProviderPage {...props} />}
        />
        <Route
          exact
          path="/logout"
          name="Logout User"
          render={(props) => <LogoutPage {...props} />}
        />
        <Route
          exact
          path="/registration"
          name="Register User"
          render={(props) => <RegisterPage {...props} />}
        />
        <Route
          exact
          path="/error"
          name="Error"
          render={(props) => <ErrorPage {...props} />}
        />
        <Route exact path="/debug" /> {/* dead endpoint so we are not redirected in the browser */}
        <Route
          path="/"
          name="Home"
          render={(props) => {
            if (!isAuthenticated || (params.redirectUri && !params.attributeChanged)) {
              // Directed to login from client portal -- TODO: maybe we should make this determination elsewhere
              return <LoginEnterEmailPage {...props} />;
              /*} else if (isAuthenticated && params.attributeChanged) {
              // Directed from verification email -- TODO: verify that unauthenticated navigates to verification after login
              return <VerifyAttributePage {...props} />*/
            } else {
              return <RedirectPage {...props} />;
            }
          }}
        />
      </Switch>
      <FlashToasts />
    </React.Suspense>
  );
}
