// External
import {
  FlashBanner,
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputPassword,
  FlashInfoMessageType,
} from "@flashparking-inc/ux-lib-react";
import { FormEventHandler, Fragment } from "react";
import { useTranslation } from "react-i18next";

type LoginEnterPasswordFormProps = {
  doLogin: FormEventHandler;
  forgotPasswordHref: string;
  errors?: { service?: string };
  isNewUser?: boolean;
  email?: string;
  submitDisabled?: boolean;
};

export default function LoginEnterPasswordForm({
  doLogin,
  errors,
  isNewUser,
  email,
  forgotPasswordHref,
  submitDisabled,
}: LoginEnterPasswordFormProps) {
  const { t } = useTranslation();
  const validationMessages: FlashFormFieldValidationMessageProps[] = errors?.service
    ? [{ type: "invalid", message: errors.service }]
    : [];

  return (
    <Fragment>
      {isNewUser && (
        <div className="mb-flash-300">
          <FlashBanner
            type={FlashInfoMessageType.success}
            title={t("LOGIN:TEMP_PASSWORD_SENT")}
            Icon={null}
          />
        </div>
      )}
      <header className="flash-font-heading-xl">
        {isNewUser ? t("LOGIN:VERIFY_ACCOUNT") : t("LOGIN:LOGIN")}
      </header>
      {isNewUser && <p className="flash-font-body-m">{t("LOGIN:VERIFY_ACCOUNT_HINT")}</p>}
      <form onSubmit={doLogin} noValidate className="my-flash-300 d-flex flex-column gap-flash-300">
        <input value={email} hidden readOnly autoComplete="email" />
        <FlashFormInputPassword
          id="password"
          label={{ text: isNewUser ? t("LOGIN:TEMP_PASSWORD") : t("COMMON:PASSWORD") }}
          autoFocus
          autoComplete="current-password"
          validationMessages={validationMessages}
        />
        <FlashButton
          id="login-enter-password-submit"
          type="submit"
          isBlock
          disabled={submitDisabled}
        >
          {isNewUser ? t("COMMON:CONTINUE") : t("LOGIN:LOGIN")}
        </FlashButton>
      </form>
      {!isNewUser && (
        <div className="text-center">
          <a className="btn btn-link my-flash-300" href={forgotPasswordHref}>
            {t("LOGIN:FORGOT_PASSWORD")}
          </a>
        </div>
      )}
    </Fragment>
  );
}
