// External
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Local
import BasePage from "lib/components/presentation/BasePage";
import { addOrReplaceQueryStringValue, encodeQueryStringToState, getQueryStringParams } from "lib/utils/http";

// Forms
import LoginEnterEmailForm from "../forms/LoginEnterEmailForm"

//Services
import { AuthService } from "lib/services/auth";

const LoginEnterEmailPage = () => {
  // Utilities
  const { t } = useTranslation();
  const history = useHistory();

  // Local state
  const [errors, setErrors] = useState();
  const [submitting, setSubmitting] = useState();
  const params = getQueryStringParams();

  const setEmail = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    if (!email.length || event.currentTarget.elements.email.validity.typeMismatch) {
      setErrors({"service": t("LOGIN:INVALID_EMAIL")});
      setSubmitting(false);
      return;
    }
    setErrors();

    //If the domain is external, we will bypass password input and redirect to the 3rd-party IdP
    const providerResult = await AuthService.isExternalProviderDomain(email);
    if (providerResult.isExternal) {
      return AuthService.authorizeProvider(providerResult.provider, encodeQueryStringToState());
    }

    const qs = addOrReplaceQueryStringValue('email', email);
    history.push(
      {
        pathname: `/login/password`,
        search: qs
      }
    );
  };

  const pageContent = <LoginEnterEmailForm
    setEmail={setEmail}
    submitting={submitting}
    defaultEmail={params?.username}
    errors={errors}
  />;

  return <BasePage pageContent={pageContent} isLoading={submitting} />
};

export default LoginEnterEmailPage;
