// External
import { useTranslation } from "react-i18next";

// Local
import BasePage from "lib/components/presentation/BasePage";
import { getQueryStringParams } from "lib/utils/http";

const ErrorPage = () => {
  const { t } = useTranslation();

  const params = getQueryStringParams();
  const { referrer } = params;

  const pageContent = (
    <div className="d-flex flex-column gap-flash-100 mb-flash-300">
      <header className="flash-font-heading-xl">{t("ERROR:GENERIC_ERROR")}</header>
      <p variant="body1">{t("ERROR:GO_BACK_DESC_1")}</p>
      <p variant="body1">{t("ERROR:GO_BACK_DESC_2")}</p>
      <div className="text-center">
        <a href={referrer || "/"} className="btn btn-link">
          {t("ERROR:TRY_AGAIN")}
        </a>
      </div>
    </div>
  );

  return <BasePage pageContent={pageContent} />;
};

export default ErrorPage;
