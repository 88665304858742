import {
  FlashBanner,
  FlashBannerProps,
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputPassword,
  FlashInfoMessageType,
  useInsights,
} from "@flashparking-inc/ux-lib-react";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasePage from "lib/components/presentation/BasePage";
import { AuthService } from "lib/services/auth";
import generateErrorMessage from "modules/login/utils/generateErrorMessage";
import { useAuthentication } from "lib/context/AuthenticationContext";
import { useHistory } from "react-router";
import { getQueryStringParamsFromHistory } from "lib/services/auth/handleAuthChallengeResponse";
import apiErrorCodeEnum from "lib/services/apiErrorCodeEnum";
import { TrackAnalyticsEventOptions } from "@flashparking-inc/ux-lib-react/dist/types";
import { EventProperties, ObjectProperties } from "@parkwhiz-js/insights-sdk/src";
import { passwordValidationMessageType, validatePasswordUsingPolicy } from "lib/utils/passwords/validatePasswordUsingPolicy";
import {ApiError} from "../../../lib/services/apiClient";

const PAGE_ANALYTICS: Partial<TrackAnalyticsEventOptions> = {
  pageType: "PortalSettings",
  pageName: "UserAccount",
};

/** Page user lands on when they are changing their own password */
export default function ChangePasswordPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const { trackAnalyticsEvent } = useInsights();
  const { currentUser, loadingUser } = useAuthentication();

  const [submitting, setSubmitting] = useState(false);
  const [currentPasswordFieldTouched, setCurrentPasswordFieldTouched] = useState(false);
  const [newPasswordFieldTouched, setNewPasswordFieldTouched] = useState(false);
  const [serviceError, setServiceError] = useState<ApiError>();
  const [validationMessages, setValidationMessages] = useState<{
    currentPassword: FlashFormFieldValidationMessageProps[];
    newPassword: FlashFormFieldValidationMessageProps[];
    newPasswordConfirm: FlashFormFieldValidationMessageProps[];
  }>({
    currentPassword: [],
    newPassword: [],
    newPasswordConfirm: [],
  });
  const [willRedirect, setWillRedirect] = useState(false);
  const [formBannerProps, setFormBannerProps] = useState<FlashBannerProps>();
  const hasUnrecoverableError = formBannerProps?.type === FlashInfoMessageType.critical;

  const params = getQueryStringParamsFromHistory(history);

  const formValuesValid = Object.values(validationMessages).every((vm) =>
    vm.every((m) => m.type === "valid")
  );

  useEffect(
    function populateInitialValidationMessages() {
      const messagesAlreadySet = Object.values(validationMessages).some((v) => v.length > 0);
      if (!currentUser || loadingUser || messagesAlreadySet) {
        return;
      }

      if (currentUser.isExternal) {
        returnToPortal();
      }

      setValidationMessages({
        currentPassword: [],
        newPassword: validatePasswordUsingPolicy({
          t,
          policy: currentUser?.accountPolicy?.policy,
          password: "",
        }),
        newPasswordConfirm: [
          {
            message: t("LOGIN:RULES_MATCH"),
            type: "requirement",
          },
        ],
      });
    },
    [currentUser, loadingUser]
  );

  useEffect(
    function requireCurrentUser() {
      if (!loadingUser && !currentUser) {
        history.replace({ ...history.location, pathname: "/login" });
      }
    },
    [loadingUser, currentUser]
  );

  useEffect(
    function appendServiceErrorsToForm() {
      if (!serviceError) {
        return;
      }

      setValidationMessages((prev) => {
        const newMessages = { ...prev };

        newMessages.currentPassword = [
          ...prev.currentPassword.filter((m) => m.message !== t("COMMON:PASSWORD_REQUIRED")),
        ];
        if (
          serviceError.errorCode === apiErrorCodeEnum.invalid_parameter &&
          serviceError.details?.parameter === "currentPassword"
        ) {
          newMessages.currentPassword.unshift({
            type: "invalid",
            message: t("COMMON:PASSWORD_REQUIRED"),
          });
        }

        if (serviceError.errorCode === apiErrorCodeEnum.not_authorized) {
          newMessages.currentPassword.unshift({
            type: "invalid",
            message: t("LOGIN:ERROR_INCORRECT_PASSWORD"),
          });
        }

        newMessages.newPassword = [
          ...prev.newPassword.filter(
            (m) =>
              m.message !== t("LOGIN:RULES_RECENT_PASSWORD") &&
              !m.message.startsWith(t("LOGIN:ERROR_INVALID_PASSWORD", { message: "" }))
          ),
        ];

        if (serviceError.errorCode === apiErrorCodeEnum.recently_used_password) {
          newMessages.newPassword.unshift({
            type: "invalid",
            message: t("LOGIN:RULES_RECENT_PASSWORD"),
          });
        }

        if (serviceError.errorCode === apiErrorCodeEnum.invalid_password) {
          newMessages.newPassword.unshift({
            type: "invalid",
            message: generateErrorMessage(
              serviceError.errorCode,
              serviceError.details,
              serviceError.message,
              t
            ),
          });
        }

        return newMessages;
      });
    },
    [serviceError]
  );

  function returnToPortal() {
    setWillRedirect(true);
    if (params.returnTo) {
      window.open(params.returnTo, "_self");
      return;
    }

    // If they didn't tell us where to send them back to, kick them out to the redirect screen
    history.replace({ ...history.location, pathname: "/redirect" });
  }

  function validateForm(event: FormEvent<HTMLFormElement>) {
    const data = new FormData(event.currentTarget);
    const currentPassword: string = (data.get("currentPassword") as string) || "";
    const newPassword: string = (data.get("newPassword") as string) || "";
    const newPasswordConfirm: string = (data.get("newPasswordConfirm") as string) || "";

    const currentPasswordValidationMessages: FlashFormFieldValidationMessageProps[] =
      !currentPassword.length && currentPasswordFieldTouched
        ? [{ message: t("COMMON:PASSWORD_REQUIRED"), type: "invalid" }]
        : [];

    const newPasswordValidationMessages: FlashFormFieldValidationMessageProps[] =
      validatePasswordUsingPolicy({
        t,
        policy: currentUser?.accountPolicy?.policy,
        password: newPassword,
        passwordFieldTouched: newPasswordFieldTouched,
      });

    const newPasswordConfirmValidationMessages: FlashFormFieldValidationMessageProps[] = [
      {
        message: t("LOGIN:RULES_MATCH"),
        type: passwordValidationMessageType(newPassword.length > 0 && newPassword === newPasswordConfirm),
      },
    ];

    setValidationMessages({
      currentPassword: currentPasswordValidationMessages,
      newPassword: newPasswordValidationMessages,
      newPasswordConfirm: newPasswordConfirmValidationMessages,
    });
  }

  async function changePassword(event: FormEvent<HTMLFormElement>) {
    setServiceError(undefined);
    event.preventDefault();
    setSubmitting(true);

    const data = new FormData(event.currentTarget);
    const currentPassword = data.get("currentPassword");
    const newPassword = data.get("newPassword");

    try {
      await AuthService.changePassword(currentPassword, newPassword);
      setSubmitting(false);
      setWillRedirect(true);
      setFormBannerProps({
        type: FlashInfoMessageType.success,
        title: t("COMMON:SUCCESS"),
        children: t("COMMON:WILL_BE_REDIRECTED"),
      });
      trackAnalyticsEvent({
        ...PAGE_ANALYTICS,
        ...EventProperties.IMPRESSION,
        ...ObjectProperties.BANNER,
        name: "PasswordSuccessfullyChanged",
      });
      setTimeout(returnToPortal, 3 * 1000);
    } catch (error) {
      const errorCode = (error as ApiError)?.errorCode;
      setServiceError(error as ApiError);
      if (!isRecoverableError(errorCode)) {
        const errorMessage = generateErrorMessage(
          errorCode,
          (error as ApiError).details,
          (error as ApiError).message,
          t
        );
        setFormBannerProps({
          type: FlashInfoMessageType.critical,
          title: t("COMMON:ERROR"),
          children: errorMessage,
        });
        return;
      }

      setServiceError(error as ApiError);
      setSubmitting(false);
    }
  }

  const PageContent = (
    <div>
      {!willRedirect && (
        <header className="flash-font-heading-xl">{t("PROFILE:CHANGE_PASSWORD")}</header>
      )}
      {formBannerProps && (
        <div>
          <FlashBanner {...formBannerProps} />
          {hasUnrecoverableError && (
            <div className="d-flex gap-flash-100 justify-content-end my-flash-200">
              <FlashButton
                id="change-password-critical-error-cancel-btn"
                color="tertiary"
                onClick={returnToPortal}
              >
                {t("COMMON:CANCEL")}
              </FlashButton>
              <FlashButton
                id="change-password-critical-error-retry-btn"
                onClick={() => {
                  window.location.reload();
                  return false;
                }}
              >
                {t("COMMON:TRY_AGAIN")}
              </FlashButton>
            </div>
          )}
        </div>
      )}
      {!willRedirect && !hasUnrecoverableError && (
        <form
          onSubmit={changePassword}
          onChange={(e) => {
            validateForm(e);
          }}
          noValidate
          className="my-flash-300 d-flex flex-column gap-flash-300"
        >
          <input value={currentUser?.email || ""} hidden readOnly autoComplete="username" />
          <FlashFormInputPassword
            id="currentPassword"
            label={{ text: t("PROFILE:CURRENT_PASSWORD") }}
            validationMessages={validationMessages.currentPassword}
            autoFocus
            autoComplete="current-password"
            onBlur={() => {
              setCurrentPasswordFieldTouched(true);
            }}
            analytics={{
              common: {
                ...PAGE_ANALYTICS,
                name: "CurrentPassword",
              },
            }}
          />
          <FlashFormInputPassword
            id="newPassword"
            autoComplete="new-password"
            label={{ text: t("COMMON:NEW_PASSWORD") }}
            validationMessages={validationMessages.newPassword}
            onBlur={() => {
              setNewPasswordFieldTouched(true);
            }}
            onChange={() => {
              if (
                serviceError?.errorCode === apiErrorCodeEnum.recently_used_password ||
                serviceError?.errorCode === apiErrorCodeEnum.invalid_password
              ) {
                setServiceError(undefined);
              }
            }}
            analytics={{
              common: {
                ...PAGE_ANALYTICS,
                name: "NewPassword",
              },
            }}
          />
          <FlashFormInputPassword
            id="newPasswordConfirm"
            autoComplete="new-password"
            label={{ text: t("COMMON:CONFIRM_NEW_PASSWORD") }}
            validationMessages={validationMessages.newPasswordConfirm}
            analytics={{
              common: {
                ...PAGE_ANALYTICS,
                name: "ConfirmPassword",
              },
            }}
          />
          <div className="d-flex gap-flash-200 justify-content-end">
            <FlashButton
              id="change-password-form-cancel-btn"
              onClick={returnToPortal}
              disabled={submitting || willRedirect}
              color="tertiary"
              analytics={{ common: { ...PAGE_ANALYTICS, name: "CancelPassword" } }}
            >
              {t("COMMON:CANCEL")}
            </FlashButton>
            <FlashButton
              id="change-password-form-submit-btn"
              type="submit"
              disabled={!formValuesValid || submitting || willRedirect}
              isLoading={submitting}
              analytics={{ common: { ...PAGE_ANALYTICS, name: "ChangePassword" } }}
            >
              {t("COMMON:SAVE")}
            </FlashButton>
          </div>
        </form>
      )}
    </div>
  );

  return (
    <BasePage
      pageContent={PageContent}
      isLoading={!currentUser || submitting || willRedirect}
      preventAuthCheckRedirect
      showHeaderBanner={false}
      showSupportLink={false}
    />
  );
}

function isRecoverableError(errorCode?: string) {
  return (
    errorCode === apiErrorCodeEnum.recently_used_password ||
    errorCode === apiErrorCodeEnum.invalid_password ||
    errorCode === apiErrorCodeEnum.invalid_parameter ||
    errorCode === apiErrorCodeEnum.validation_failed ||
    errorCode === apiErrorCodeEnum.not_authorized
  );
}
