import { FlashButton, FlashIconClose, FlashNamespaced } from "@flashparking-inc/ux-lib-react";
import classNames from "classnames";
import ContentWrapper, { ContentWrapperProps } from "./ContentWrapper";
import { PropsWithChildren } from "react";

import "./BasePageLayout.scss";
import { useTranslation } from "react-i18next";

type BasePageLayoutProps = PropsWithChildren<
  {
    /** Show message header */
    showHeader?: boolean;
    /** Function to invoke to hide the header */
    hideHeader?: () => void;
  } & Pick<ContentWrapperProps, "showSupportLink">
>;

export default function BasePageLayout(props: BasePageLayoutProps) {
  const { showHeader, hideHeader, showSupportLink, children } = props;
  const { t } = useTranslation();

  return (
    <FlashNamespaced>
      <div id="app-content-wrapper" className="vh-100 d-flex flex-column">
        <div
          id="app-content-message-header"
          className={classNames("bg-inverse text-inverse_primary d-flex align-items-center", {
            show: showHeader,
          })}
        >
          <div className="flex-grow-1 d-flex justify-content-center">
            <span>
              {t("COMMON:HEADER_MESSAGE")}
              <a
                className="btn btn-link text-inverse_primary ms-flash-050"
                href="https://flash-knowledge.flashos.com/support/solutions/articles/151000148926-log-in-changes"
                target="_blank"
                rel="noopener"
              >
                {t("COMMON:HEADER_LINK_TEXT")}
              </a>
            </span>
          </div>
          <FlashButton
            id="app-content-message-header-dismiss"
            color="icon_only_default"
            className="text-inverse_primary"
            LeadingIcon={FlashIconClose}
            onClick={hideHeader}
          />
        </div>
        <div className="flex-grow-1 d-flex">
          <div id="app-content-desktop-filler" className="w-50 d-none d-lg-block" />
          <div
            id="app-content-main-panel"
            className="flex-grow-1 d-flex justify-content-center align-items-sm-center py-flash-300"
          >
            <ContentWrapper showSupportLink={showSupportLink}>{children}</ContentWrapper>
          </div>
        </div>
      </div>
    </FlashNamespaced>
  );
}
