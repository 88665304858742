// External
import React, { ReactNode, useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";

// Local
import { AuthService } from "lib/services/auth";
import { useAuthentication } from "lib/context/AuthenticationContext";
import { getQueryStringParams } from "lib/utils/http";

// Components
import LoadingPage from "lib/components/presentation/LoadingPage";

import LoadingOverlay from "./LoadingOverlay";
import BasePageLayout from "./BasePageLayout";
import { FlashBanner, FlashInfoMessageType } from "@flashparking-inc/ux-lib-react";
import { RouterState } from "lib/types";

type BasePageProps = {
  isLoading?: boolean;
  pageContent: ReactNode;
  showSupportLink?: boolean;
  showHeaderBanner?: boolean;
  /** Prevent redirects during auth checks for this page */
  preventAuthCheckRedirect?: boolean;
};

export default function BasePage(props: BasePageProps) {
  const {
    isLoading = false,
    pageContent,
    showSupportLink,
    showHeaderBanner,
    preventAuthCheckRedirect,
  } = props;
  const params = getQueryStringParams();
  const history = useHistory<RouterState>();

  const [loginError, setLoginError] = useState();
  const [cachedAuth, setCachedAuth] = useState(!loginError);
  const [loading, setLoading] = useState(false);
  const [showHeader, setShowHeader] = useState(
    showHeaderBanner ?? sessionStorage.getItem("hideHeader") !== "true"
  );

  const errorBanner = history.location?.state?.errorBanner;

  // Handle previous authentication result
  useEffect(() => {
    checkAuthState();

    async function checkAuthState() {
      setLoading(true);
      const result = await AuthService.checkAuthenticationState(params, preventAuthCheckRedirect);
      const { error, cachedAuth, redirectPath, forceLogout } = result;

      setLoginError(error);
      setCachedAuth(cachedAuth);
      setLoading(false);

      if (forceLogout) {
        history.push(redirectPath);
      }
    }
  }, []);

  function hideHeader() {
    sessionStorage.setItem("hideHeader", "true");
    setShowHeader(false);
  }

  function clearErrorBanner() {
    const stateWithoutBanner = Object.entries(history.location.state || {}).reduce(
      (result, [key, value]) => {
        if (key !== "errorBanner") {
          return { ...result, [key]: value };
        }

        return result;
      },
      {}
    );
    history.replace({ state: stateWithoutBanner });
  }

  const { isAuthenticated } = useAuthentication();

  // If we are already authenticated, redirect
  return isAuthenticated &&
    !params.redirectUri &&
    !params.error &&
    !preventAuthCheckRedirect ? (
    <Redirect to={{ pathname: "/redirect", search: window.location.search }} />
  ) : (
    // Otherwise render the page content
    <React.Suspense fallback={<LoadingPage />}>
      <LoadingOverlay visible={isLoading || loading || (cachedAuth && !preventAuthCheckRedirect)} />
      <BasePageLayout
        showHeader={showHeader}
        hideHeader={hideHeader}
        showSupportLink={showSupportLink}
      >
        {loginError && (
          <FlashBanner type={FlashInfoMessageType.warning} className="mb-flash-300">
            {loginError}
          </FlashBanner>
        )}
        {!loginError && errorBanner && (
          <FlashBanner
            bannerId="base-page-error-banner"
            type={FlashInfoMessageType.critical}
            title={errorBanner.title}
            className="mb-flash-300"
            onClose={clearErrorBanner}
          >
            {errorBanner.description}
          </FlashBanner>
        )}
        {(!cachedAuth || preventAuthCheckRedirect) && pageContent}
      </BasePageLayout>
    </React.Suspense>
  );
}
