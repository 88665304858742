import BasePage from "lib/components/presentation/BasePage";
import LoginMfaPhoneNumberForm from "../forms/LoginMfaPhoneNumberForm";
import { useState } from "react";
import { useAuthentication } from "lib/context/AuthenticationContext";
import { AuthService } from "lib/services/auth";
import rollbar from "../../../lib/vendor/rollbar/rollbarService";
import generateErrorMessage from "../utils/generateErrorMessage";
import { useTranslation } from "react-i18next";
import { ApiError } from "lib/services/apiClient";
import { Redirect, useHistory } from "react-router";
import { handleAuthChallengeResponse } from "lib/services/auth/handleAuthChallengeResponse";

export default function MfaPhoneNumberRequiredPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const { challengeResult, setAuthChallengeResult } = useAuthentication();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ service?: string }>({});

  async function onSubmit({ phoneNumber }: { phoneNumber?: string }) {
    setIsSubmitting(true);
    setErrors({});

    try {
      const data = { phoneNumber }
      const mfaRequiredChallengeResult = await AuthService.mfaRequiredChallenge(
        challengeResult?.challenge,
        data
      );
      await handleAuthChallengeResponse({
        history,
        setAuthChallengeResult,
        data,
        challengeResult: mfaRequiredChallengeResult,
      });
    } catch (error) {
      rollbar.error(error as Error);
      const errorMessage = generateErrorMessage(
        (error as ApiError).errorCode,
        (error as ApiError).details,
        (error as ApiError).message,
        t
      );
      setErrors({ service: errorMessage });
      setIsSubmitting(false);
    }
  }

  const pageContent = challengeResult ? (
    <LoginMfaPhoneNumberForm
      onSubmit={onSubmit}
      serviceError={errors?.service}
    />
  ) : (
    <Redirect to="/login" />
  );

  return <BasePage pageContent={pageContent} isLoading={isSubmitting} />;
}
