import {
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputTextField,
} from "@flashparking-inc/ux-lib-react";
import { addOrReplaceQueryStringValue } from "lib/utils/http";
import { updateEmailQueryParam } from "lib/utils/queryParams/updateEmailQueryParam";
import { FormEventHandler, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

type ForgotPasswordFormProps = {
  doForgot: FormEventHandler;
  errors?: { service?: string };
  email?: string;
};

export default function ForgotPasswordForm({ errors, email, doForgot }: ForgotPasswordFormProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const validationMessages: FlashFormFieldValidationMessageProps[] = errors?.service
    ? [
        {
          type: "invalid",
          message: errors.service,
        },
      ]
    : [];

  function updateEmailParam(value?: string) {
    updateEmailQueryParam(history, value);
  }

  return (
    <Fragment>
      <header className="flash-font-heading-xl">{t("LOGIN:FORGOT_PASSWORD_QUESTION")}</header>
      <p className="flash-font-body-m">{t("LOGIN:FORGOT_PASSWORD_DESC")}</p>
      <form
        onSubmit={doForgot}
        noValidate
        className="my-flash-300 d-flex flex-column gap-flash-300"
      >
        <FlashFormInputTextField
          id="email"
          label={{ text: t("COMMON:EMAIL") }}
          autoComplete="email"
          autoFocus
          defaultValue={email}
          validationMessages={validationMessages}
          onChange={updateEmailParam}
        />
        <FlashButton id="forgot-password-submit" type="submit" isBlock>
          {t("COMMON:CONTINUE")}
        </FlashButton>
      </form>
      <div className="text-center">
        <a
          className="btn btn-link my-flash-300"
          href={
            email
              ? `/login/password${history.location.search}`
              : `/login/${history.location.search}`
          }
        >
          {t("LOGIN:BACK_TO_LOGIN")}
        </a>
      </div>
    </Fragment>
  );
}
