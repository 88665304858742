import { FlashNamespaced, FlashSpinner } from "@flashparking-inc/ux-lib-react";

export default function LoadingOverlay(props: { visible?: boolean }) {
  if (!props.visible) {
    return null;
  }

  return (
    <FlashNamespaced>
      <div
        className="position-absolute top-0 start-0 vh-100 vw-100 bg-inverse opacity-75 d-flex align-items-center justify-content-center text-inverse_primary"
        style={{ zIndex: 10 }}
      >
        <FlashSpinner size="7.5rem" />
      </div>
    </FlashNamespaced>
  );
}
