// External
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Local
import BasePage from "lib/components/presentation/BasePage";
import { addOrReplaceQueryStringValues, getQueryStringParams } from "lib/utils/http";
import generateRegistrationErrorMessage from "../utils/generateRegistrationErrorMessage";
import { isValidEmail } from "lib/utils/strings";

// Forms
import RegisterForm from "../forms/RegisterForm"

//Services
import { AuthService } from "lib/services/auth";
import {Logger} from "../../../lib/utils/logging";

const RegisterPage = () => {
  // Utilities
  const { t } = useTranslation();
  const history = useHistory();

  // Local state
  const [errors, setErrors] = useState();
  const [submitting, setSubmitting] = useState(true);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [referrer, setReferrer] = useState();

  const params = getQueryStringParams();

  const { token, clientType } = params;

  useEffect(async () => {
    if (!token || !clientType) {
      setSubmitting(false);
      Logger.error("Token or client type missing.");
      window.location.href = "/error";
      return;
    }

    try {
      const reg = await AuthService.startUserRegistration(clientType, token);
      setSubmitting(false);
      setReferrer(reg.referrer);
      setFirstName(reg.firstName);
      setLastName(reg.lastName);
      setEmail(reg.email);
    } catch (e) {
      setSubmitting(false);
      Logger.error("Could not begin user registration.");
      Logger.error(e);
      window.location.href = "/error";
    }
  }, []);

  const doSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    if (!email.length || !isValidEmail(email)) {
      setErrors({"service": t("LOGIN:INVALID_EMAIL")});
      setSubmitting(false);
      return;
    }

    try {
      await AuthService.finalizeUserRegistration(clientType, token, email);
      setSubmitting(false);
    }
    catch (error) {
      const errorMessage = generateRegistrationErrorMessage(error.errorCode, error.details, error.message, t);
      setSubmitting(false);
      setErrors({"service": errorMessage});
      Logger.error("Could not finalize user registration.")
      Logger.error(error);
      return;
    }

    setErrors();

    let qs = addOrReplaceQueryStringValues({
      'username': email,
      'newUser': true,
      'referrer': referrer
    });

    history.push(
      {
        pathname: `/login/password`,
        search: qs
      }
    );
  };

  function clearErrors() {
    setErrors()
  }

  const pageContent = <RegisterForm
    doSubmit={doSubmit}
    submitting={submitting}
    defaultEmail={email}
    defaultFirstName={firstName}
    defaultLastName={lastName}
    errors={errors}
    onEmailChange={clearErrors}
  />;

  return <BasePage
    pageContent={pageContent}
    isLoading={submitting}
  />
};

export default RegisterPage;
