import {getQueryStringParams} from "../../../lib/utils/http";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {AuthService} from "../../../lib/services/auth";
import LoadingPage from "../../../lib/components/presentation/LoadingPage";
import {useTranslation} from "react-i18next";

const ExternalProviderPage = (props) => {
  const qsParams = getQueryStringParams();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(async () => {
    const { code, error_description } = qsParams;

    if (qsParams.error) {
      const searchParams = new URLSearchParams({
        error: t(`IDP:${qsParams.error.toUpperCase()}`) || t("IDP:UNKNOWN"),
        errorDescription: error_description
      });
      //Stop processing and send back to main page w/error
      history.push(`/?${searchParams.toString()}`);
      return;
    }

    const { params, error, redirectPath } = await AuthService.processCodeExchangeForProvider(code);
    if (error) {
      params.error = t(`IDP:${error.toUpperCase()}`) || t("IDP:UNKNOWN");
    }

    const searchParams = new URLSearchParams(params);
    history.push(`${redirectPath}?${searchParams.toString()}`);
  }, []);

  //If we land here, it's an unhandled error
  return <LoadingPage />;
};

export default ExternalProviderPage;
