import {
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputTextField,
} from "@flashparking-inc/ux-lib-react";
import { FormEventHandler, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface VerifyEmailFormProps {
  setVerificationCode: FormEventHandler;
  onCodeChange: (value?: string) => void | Promise<void>;
  codeIsValid?: boolean;
  email?: string;
  error?: string;
  oneTimeCode?: string;
}

export default function VerifyEmailForm({
  setVerificationCode,
  onCodeChange,
  codeIsValid,
  email,
  error,
  oneTimeCode,
}: VerifyEmailFormProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const validationMessages: FlashFormFieldValidationMessageProps[] = error ? [] : [];

  useEffect(() => {
    if (oneTimeCode?.length) {
      onCodeChange(oneTimeCode);
    }
  }, []);

  function handleCodeChange(value?: string) {
    onCodeChange(value);
  }

  return (
    <Fragment>
      <header className="flash-font-heading-xl">{t("LOGIN:ENTER_VER_CODE")}</header>
      <p>{t("LOGIN:ENTER_VER_CODE_DESC")} <span className="fw-bold">{email}</span></p>
      <form
        onSubmit={setVerificationCode}
        noValidate
        className="my-flash-300 d-flex flex-column gap-flash-300"
      >
        <FlashFormInputTextField
          id="email_code"
          label={{
            text: t("LOGIN:ENTER_VER_CODE"),
          }}
          type="number"
          defaultValue={oneTimeCode}
          onChange={handleCodeChange}
          autoFocus
          validationMessages={validationMessages}
        />
        <FlashButton id="verify-email-form-submit" type="submit" disabled={!codeIsValid} isBlock>
          {t("COMMON:CONTINUE")}
        </FlashButton>
      </form>
      <div className="text-center mb-flash-300">
        <FlashButton
          id="verify-email-return-to-login"
          color="link"
          onClick={() => {
            history.push({ pathname: "/", search: history.location.search });
          }}
        >
          {t("LOGIN:BACK_TO_LOGIN")}
        </FlashButton>
      </div>
    </Fragment>
  );
}
