import "regenerator-runtime/runtime";

import { FlashAppContextProvider, ToastMaster } from "@flashparking-inc/ux-lib-react";
import ReactDOM from "react-dom";
import React from "react";

import { BrowserRouter } from "react-router-dom";
import { Provider as RollbarProvider, ErrorBoundary as RollBarErrorBoundary } from "@rollbar/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { LogLevel, Logger } from "./lib/utils/logging";
import "lib/config/i18n";

import Environment, { DEV, LOCAL } from "lib/utils/environment";

import { AuthenticationContextProvider } from "lib/context/AuthenticationContext";
import { rollbarConfig } from "lib/utils";
import "lib/services/apiClient/apiSetup";

import App from "./App";

const cacheMinutes = 3;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * cacheMinutes,
      cacheTime: 1000 * 60 * cacheMinutes * 2,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});
const toastMaster = new ToastMaster()

let isDev = true;
if (Environment.environment !== DEV && Environment.environment !== LOCAL) {
  isDev = false;
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => {} : null;
    }
  }
}

Logger.setLogLevel(isDev ? LogLevel.info : LogLevel.warn);

ReactDOM.render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <RollBarErrorBoundary>
        <BrowserRouter>
          <FlashAppContextProvider
            loggerProps={{ level: isDev ? LogLevel.debug : LogLevel.warn }}
            insightsProps={{
              options: {
                baseURL: Environment.insightsBaseUrl,
                apiKey: Environment.insightsApiKey,
              },
            }}
            toastProps={{ toastMaster }}
          >
            <AuthenticationContextProvider>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </AuthenticationContextProvider>
          </FlashAppContextProvider>
        </BrowserRouter>
      </RollBarErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
