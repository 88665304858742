import {
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputTextField,
} from "@flashparking-inc/ux-lib-react";
import {
  addOrReplaceQueryStringValue,
  addOrReplaceQueryStringValues,
  getQueryStringParams,
} from "lib/utils/http";
import { updateEmailQueryParam } from "lib/utils/queryParams/updateEmailQueryParam";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

type LoginEnterEmailFormProps = {
  setEmail: () => void;
  submitting?: boolean;
  errors?: { service?: string };
  defaultEmail?: string;
};

export default function LoginEnterEmailForm({
  setEmail,
  submitting,
  errors,
  defaultEmail,
}: LoginEnterEmailFormProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const validationMessages: FlashFormFieldValidationMessageProps[] = errors?.service
    ? [
        {
          type: "invalid",
          message: errors.service,
        },
      ]
    : [];

  function updateEmailParam(value?: string) {
    updateEmailQueryParam(history, value);
  }

  return (
    <Fragment>
      <header className="flash-font-heading-xl">{t("LOGIN:LOGIN")}</header>
      <form
        onSubmit={setEmail}
        noValidate
        className="my-flash-300 d-flex flex-column gap-flash-300"
      >
        <FlashFormInputTextField
          id="email"
          type="email"
          label={{ text: t("COMMON:EMAIL") }}
          defaultValue={defaultEmail}
          placeholder={t("LOGIN:ENTER_EMAIL")}
          autoComplete="email"
          autoFocus
          disabled={submitting}
          validationMessages={validationMessages}
          onChange={updateEmailParam}
        />
        <FlashButton id="login-enter-email-submit" type="submit" isBlock>
          {t("COMMON:CONTINUE")}
        </FlashButton>
      </form>
    </Fragment>
  );
}
