import { TFunction } from "i18next";
import apiErrorCodeEnum from "../../../lib/services/apiErrorCodeEnum";
import { ErrorBanner } from "lib/types";

const generateErrorBanner = (
  t: TFunction,
  errorCode: (typeof apiErrorCodeEnum)[keyof typeof apiErrorCodeEnum]
): ErrorBanner | null => {
  let errorBanner: ErrorBanner | null = null;
  switch (errorCode) {
    case apiErrorCodeEnum.too_many_requests:
      errorBanner = {
        title: t("LOGIN:TOO_MANY_FAILED"),
        description: t("LOGIN:TOO_MANY_FAILED_CODE_DETAIL"),
      };
      break;
    default:
      break;
  }
  return errorBanner;
};
export default generateErrorBanner;
