import {getQueryStringParams, decodeClientStateFromQS} from "../../lib/utils/http";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {authResponseCodeEnum, AuthService} from "../../lib/services/auth";
import LoadingPage from "../../lib/components/presentation/LoadingPage";
import {useTranslation} from "react-i18next";
import {ApiRequest} from "../../lib/services/apiClient/requests/apiRequest";
import {launchUri} from "../../lib/utils/browser";
import {base64Decode, base64Encode} from "../../lib/utils/encoding";
import {getLocalStorage, removeFromLocalStorage, setLocalStorage} from "../../lib/utils";

/**
 * This will initiate and eventually complete the logout process for a cognito session (cookie) on our
 * cognito Auth domain.  During the logout process, the following occurs:
 * 1. A logout request occurred (AuthService.logout) which writes a "clear session" flag to local storage
 * 2. Upon attempting to login again, the system will recognize this flag and redirect to /logout
 * 3. On first pass to this component, it will initiate the logout process against our cognito Auth domain. This
 *    will delete the session cookie on that domain. This will allow for re-prompting of 3rd party Idp users to pick
 *    the account to login with (e.g. Google account chooser)
 * 4. On the second pass, it will capture the original querystring data and redirect back to the root path `/` with
 *    the original querystring data to start the login process
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const LogoutPage = (props) => {
  const params = getQueryStringParams();
  const history = useHistory();

  useEffect(async () => {
    const result = await AuthService.logoutCognitoSession(params);
    if (result?.redirectPath) {
      history.push(result.redirectPath);
    }
  }, []);

  //If we land here, it's an unhandled error
  return <LoadingPage />;
};

export default LogoutPage;
