// External
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Local
import { AuthService } from "lib/services/auth";
import generateErrorMessage from "modules/login/utils/generateErrorMessage";
import { addOrReplaceQueryStringValue, getQueryStringParams } from "lib/utils/http";
import { base64Encode } from "lib/utils/encoding";
import BasePage from "lib/components/presentation/BasePage";
import rollbar from "../../../lib/vendor/rollbar/rollbarService";

// Forms
import ForgotPasswordForm from "../forms/ForgotPasswordForm";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);

  const params = getQueryStringParams();

  const doForgot = async (event) => {
    try {
      event.preventDefault();
      setSubmitting(true);

      const data = new FormData(event.currentTarget);
      const email = data.get('email');

      const clientType = params.clientType ?? "portal";

      const options = {
        locale: i18n.resolvedLanguage,
        ...( params.redirectUri ? {state: base64Encode(JSON.stringify({ redirectUri: params.redirectUri }))} : {})
      };

      await AuthService.forgotPassword(email, clientType, options);
      goToVerify(email);
    } catch (err) {
      rollbar.error(err);
      const errorMessage = generateErrorMessage(err.errorCode, err.details, err.message, t);
      setErrors({ service: errorMessage });
      setSubmitting(false);
    }
  };

  const goToVerify = (username) => {
    const qs = addOrReplaceQueryStringValue("username", username);
    history.push({
      pathname: `/verify/attribute`,
      search: qs
    });
  };

  const pageContent = <ForgotPasswordForm
    errors={errors}
    email={params.email}
    doForgot={doForgot}
  />;

  return <BasePage pageContent={pageContent} isLoading={submitting} showSupportLink={false} />
};

export default ForgotPasswordPage;
