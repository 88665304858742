// Local
import LoadingPage from "lib/components/presentation/LoadingPage";
import Environment from "lib/utils/environment";
import {getQueryStringParams} from "../../../lib/utils/http";
import {Logger} from "../../../lib/utils/logging";

const RedirectPage = (props) => {
  const params = getQueryStringParams();
  Logger.debug("REDIRECT", params);
  window.location.href = params.redirectUri || Environment.defaultRedirect;
  return LoadingPage();
};

export default RedirectPage;
