import { Fragment, Suspense } from "react";

import LoadingOverlay from "lib/components/presentation/LoadingOverlay";

import BasePageLayout from "./BasePageLayout";

export default function LoadingPage() {
  return (
    <Fragment>
      <LoadingOverlay visible />
      {/* Use `Suspense` here to wait for translations */}
      <Suspense fallback={<div data-testid="loading-fallback" />}>
        <BasePageLayout showHeader={false} showSupportLink={false} />
      </Suspense>
    </Fragment>
  );
}
