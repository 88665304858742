import {
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputPhoneNumber,
  usePhoneNumberInput,
} from "@flashparking-inc/ux-lib-react";
import ContentWrapper from "lib/components/presentation/ContentWrapper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface LoginEnterPhoneNumberFormProps {
  /** Callback to invoke when user clicks submit button */
  onSubmit: (value: { phoneNumber: string }) => Promise<void>;
  /** Error recieved by the service when submitting */
  serviceError?: string;
}

/** Accepts user phone number for MFA setup */
export default function LoginMfaPhoneNumberForm(props: LoginEnterPhoneNumberFormProps) {
  const { onSubmit, serviceError } = props;
  const { t } = useTranslation();

  const phoneInputState = usePhoneNumberInput();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [validationMessages, setValidationMessages] = useState<
    FlashFormFieldValidationMessageProps[]
  >([]);

  useEffect(() => {
    if (!hasSubmitted && validationMessages.length > 0) {
      setValidationMessages([]);
      return;
    }

    const newMessages: FlashFormFieldValidationMessageProps[] = [];
    if (hasSubmitted && !isValidPhoneNumber) {
      newMessages.push({
        type: "invalid",
        message: t("LOGIN:ENTER_VALID_PHONE_NUMBER"),
      });
    }

    if (hasSubmitted && serviceError) {
      newMessages.push({
        type: "invalid",
        message: serviceError,
      });
    }

    setValidationMessages(newMessages);
  }, [isValidPhoneNumber, hasSubmitted, serviceError]);

  return (
    <div className="d-flex flex-column gap-flash-100">
      <header className="flash-font-heading-xl">{t("LOGIN:MFA_ADD_PHONE_NUMBER_TITLE")}</header>
      <p className="flash-font-body-m">{t("LOGIN:MFA_ADD_PHONE_NUMBER_MESSAGE")}</p>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          setHasSubmitted(true);
          await onSubmit({ phoneNumber: phoneInputState.fullNumber });
        }}
        className="d-flex flex-column gap-flash-300"
      >
        <FlashFormInputPhoneNumber
          id="mfa-phone-number-input"
          label={{
            text: t("COMMON:PHONE_NUMBER"),
            helperText: t("LOGIN:MFA_ADD_PHONE_NUMBER_RATES_NOTICE"),
          }}
          {...phoneInputState}
          onChange={(value) => {
            phoneInputState.onChange(value);
            setIsValidPhoneNumber(value.isValid);
            setHasSubmitted(false);
          }}
          validationMessages={validationMessages}
          autoFocus
        />
        <FlashButton
          id="mfa-phone-number-submit"
          type="submit"
          disabled={!isValidPhoneNumber}
          isBlock
        >
          {t("COMMON:CONTINUE")}
        </FlashButton>
      </form>
    </div>
  );
}
