import {
  FlashBanner,
  FlashButton,
  FlashFormFieldValidationMessageProps,
  FlashFormInputTextField,
  FlashInfoMessageType,
  noOp,
} from "@flashparking-inc/ux-lib-react";
import { FormEventHandler, Fragment } from "react";
import { useTranslation } from "react-i18next";

type RegisterFormProps = {
  doSubmit: FormEventHandler;
  onEmailChange: () => void;
  defaultEmail?: string;
  defaultFirstName?: string;
  defaultLastName?: string;
  submitting?: boolean;
  errors?: { service?: string };
};

export default function RegisterForm({
  doSubmit,
  onEmailChange = noOp,
  defaultEmail,
  defaultFirstName,
  defaultLastName,
  submitting,
  errors,
}: RegisterFormProps) {
  const { t } = useTranslation();

  const validationMessages: FlashFormFieldValidationMessageProps[] = errors?.service
    ? [
        {
          type: "invalid",
          message: errors.service,
        },
      ]
    : [];

  return (
    <Fragment>
      <div className="mb-flash-300">
        <FlashBanner
          type={FlashInfoMessageType.warning}
          title={t("LOGIN:MIGRATION_HEADER")}
          Icon={null}
        >
          {t("LOGIN:MIGRATION")}
        </FlashBanner>
      </div>
      <header className="flash-font-heading-xl">{t("LOGIN:REGISTER")}</header>
      <p>{t("LOGIN:REGISTER_DESC")}</p>
      <form
        onSubmit={doSubmit}
        noValidate
        className="my-flash-300 d-flex flex-column gap-flash-300"
      >
        <FlashFormInputTextField
          id="name"
          label={{ text: t("COMMON:NAME") }}
          disabled
          defaultValue={`${defaultFirstName} ${defaultLastName}`}
        />
        <FlashFormInputTextField
          id="email"
          type="email"
          label={{ text: t("COMMON:EMAIL") }}
          required
          validationMessages={validationMessages}
          autoComplete="email"
          autoFocus
          defaultValue={defaultEmail}
          disabled={submitting}
          onChange={onEmailChange}
        />
        <FlashButton id="registration-form-submit" type="submit" isBlock>
          {t("COMMON:CONTINUE")}
        </FlashButton>
      </form>
    </Fragment>
  );
}
