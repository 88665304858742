// External
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Local
import rollbarLog from "lib/vendor/rollbar/rollbarService";
import { AuthService } from "lib/services/auth";
import { useAuthentication } from "lib/context/AuthenticationContext";

import BasePage from "lib/components/presentation/BasePage";
import generateErrorMessage from "modules/login/utils/generateErrorMessage";
import { addOrReplaceQueryStringValue, getQueryStringParams } from "lib/utils/http";

// Forms
import LoginEnterPasswordForm from "../forms/LoginEnterPasswordForm"
import { handleAuthChallengeResponse } from "lib/services/auth/handleAuthChallengeResponse";

const LoginEnterPasswordPage = () => {
  // Utilities
  const { t } = useTranslation();
  const history = useHistory();
  const params = getQueryStringParams();

  // Global state
  const { setAuthChallengeResult } = useAuthentication()

  // Local state
  const [errors, setErrors] = useState();
  const [submitting, setSubmitting] = useState();
  const [willRedirect, setWillRedirect] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();

  const forgotPasswordHref = `/password/forgot?${addOrReplaceQueryStringValue('username', params.email)}`;

  const doLogin = async (event) => {
    setErrors(undefined);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password");

    if (!password.length) {
      setErrors({ service: t("LOGIN:PASSWORD_REQUIRED") });
      return;
    }

    setSubmitting(true);
    try {
      const challengeResult = await AuthService.login(
        params.email || params.username,
        password
      );
      await handleAuthChallengeResponse({
        history,
        setAuthChallengeResult,
        challengeResult,
      });
      setWillRedirect(true);
    } catch (error) {
      const errorMessage = generateErrorMessage(
        error.errorCode,
        error.details,
        error.message,
        t
      );
      // If the user has been timed out, show a banner
      if (error.message === "Password attempts exceeded") {
        history.replace({
          state: {
            ...history.location.state,
            errorBanner: {
              title: t("LOGIN:TOO_MANY_FAILED"),
              description: t("LOGIN:TOO_MANY_FAILED_PASSWORD_DETAIL"),
            }
          }
        })
        setSubmitDisabled(true);
      } else {
        // Otherwise show the error in the form
        setErrors({ service: errorMessage });
        rollbarLog.error(error);
      }
    }
    setSubmitting(false);
  };

  const isNewUser = !!params.newUser

  const pageContent = <LoginEnterPasswordForm
    errors={errors}
    isNewUser={isNewUser}
    email={params.email || params.username}
    doLogin={doLogin}
    forgotPasswordHref={forgotPasswordHref}
    submitDisabled={submitDisabled}
  />;

  return <BasePage pageContent={pageContent} isLoading={submitting || willRedirect} showSupportLink={isNewUser} />
};

export default LoginEnterPasswordPage;
