// External
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Local
import BasePage from "lib/components/presentation/BasePage";
import { addOrReplaceQueryStringValue, getQueryStringParams } from "lib/utils/http";

// Forms
import VerifyEmailForm from "../forms/VerifyEmailForm"

const VerifyEmailPage = (props) => {
  // Utilities
  const history = useHistory();
  const params = getQueryStringParams();

  // Local state
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState();
  const [codeIsValid, setCodeIsValid] = useState();

  // Back button takes user back to login page
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        window.location.href = `/${window.location.search}`;
      }
    });
  });

  // An error can be passed from the subsequent page when the token it validated
  const state = props.location.state;
  if (state?.length && state[0].error && !error) {
    setError(state[0].error);
  }

  const setVerificationCode = async (event) => {
      event.preventDefault();

      const data = new FormData(event.currentTarget);
      const code = data.get('email_code');
      const qs = addOrReplaceQueryStringValue('code', code);
      history.push({
        pathname: `/password/reset`,
        search: qs
      });
  }

  const validateCode = (code) => {
    // Code should be 6 numerical characters
    if (code?.length === 6 && /^[0-9]+$/.test(code)) {
      setCodeIsValid(true);
    } else {
      setCodeIsValid(false);
    }
  }

  const pageContent = <VerifyEmailForm
    setVerificationCode={setVerificationCode}
    onCodeChange={validateCode}
    codeIsValid={codeIsValid}
    email={params.email}
    error={error}
    oneTimeCode={params.code}
  />;

  return <BasePage pageContent={pageContent} isLoading={submitting} showSupportLink={false} />
};

export default VerifyEmailPage;
