import Rollbar from "rollbar";
import {rollbarConfig} from "../../utils";
import {Logger, LogLevel} from "../../utils/logging";

class RollbarLog {
  private readonly rollbar: Rollbar;

  constructor() {
    this.rollbar = new Rollbar(rollbarConfig);
  }

  info(error: string | Error, ...additionalArgs: any[]) {
    this.log(LogLevel.info, error, ...additionalArgs);
  }
  warn(error: string | Error, ...additionalArgs: any[]) {
    this.log(LogLevel.warn, error, ...additionalArgs);
  }
  error (error: string | Error, ...additionalArgs: any[]) {
    this.log(LogLevel.error, error, ...additionalArgs);
  }

  critical (error: string | Error, ...additionalArgs: any[]) {
    this.log(LogLevel.critical, error, ...additionalArgs);
  }

  private log(logLevel: LogLevel, error: string | Error, ...additionalArgs: any[]) {
    const message = error instanceof Error ? error.message : error;
    try {
      this.rollbar.configure({ ...rollbarConfig, payload: { ...rollbarConfig.payload, error } });
      this.rollbar[logLevel](message, ...additionalArgs);
    } catch (e) {
      Logger.error("Could not log error to rollbar:", e);
    } finally {
      this.rollbar.configure({ ...rollbarConfig, payload: { ...rollbarConfig.payload, error: null } });
    }
  }
}

const rollbar = new RollbarLog();

// Rollbar service is a singleton
export default rollbar;
