// External
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

// Local
import { AuthService } from "lib/services/auth";
import { useAuthentication } from "lib/context/AuthenticationContext";
import generateErrorMessage from "../utils/generateErrorMessage";
import rollbar from "../../../lib/vendor/rollbar/rollbarService";
import NewPasswordEntry from "lib/components/password/NewPasswordEntry";
import BasePage from "lib/components/presentation/BasePage";
import { handleAuthChallengeResponse } from "lib/services/auth/handleAuthChallengeResponse";
import { useHistory } from "react-router";
import { ApiError } from "lib/services/apiClient";

const NewPasswordRequiredPage = () => {
  const history = useHistory();
  const { challengeResult, setAuthChallengeResult } = useAuthentication();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<{ service?: string }>({});
  const [submitting, setSubmitting] = useState(false);

  const doReset = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const newPassword = data.get("new_password");
      setSubmitting(true);
      const passwordChallengeResult = await AuthService.passwordChallenge(
        challengeResult?.challenge,
        newPassword
      );
      await handleAuthChallengeResponse({
        history,
        setAuthChallengeResult,
        challengeResult: passwordChallengeResult,
      });
    } catch (error) {
      rollbar.error(error as Error);
      const errorMessage = generateErrorMessage(
        (error as ApiError).errorCode,
        (error as ApiError).details,
        (error as ApiError).message,
        t
      );
      setErrors({ service: errorMessage });
    }
    setSubmitting(false);
  };

  const pageContent = <NewPasswordEntry onSubmit={doReset} errorMessage={errors?.service} />;

  return <BasePage pageContent={pageContent} isLoading={submitting} />;
};

export default NewPasswordRequiredPage;
