// External
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Local
import { AuthService } from "lib/services/auth";
import rollbar from "../../../lib/vendor/rollbar/rollbarService";
import BasePage from "lib/components/presentation/BasePage";
import { getQueryStringParams } from "lib/utils/http";
import NewPasswordEntry from "lib/components/password/NewPasswordEntry";

import generateErrorBanner from "../utils/generateErrorBanner";
import apiErrorCodeEnum from "lib/services/apiErrorCodeEnum";
import { ApiError } from "lib/services/apiClient";
import { RouterState } from "lib/types";

const ResetPasswordPage = () => {
  // Utilities
  const history = useHistory<RouterState>();
  const params = getQueryStringParams();
  const { t } = useTranslation();

  // Local state
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<ApiError>();

  // Back button takes user back to login page
  useEffect(() => {
    return history.listen((_) => {
      if (history.action === "POP") {
        window.location.href = `/${window.location.search}`;
      }
    });
  });

  const doNewPassword = async (event: FormEvent<HTMLFormElement>) => {
    setSubmissionError(undefined);
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const newPassword = data.get("new_password");
    const enteredCode = data.get("new_password_code");

    try {
      setSubmitting(true);
      await AuthService.resetPassword(
        params.email,
        params.code || enteredCode,
        newPassword,
        params.clientType
      );
      backToLogin();
    } catch (err) {
      const isServiceError = isApiError(err);

      if (
        isServiceError &&
        (err.errorCode === apiErrorCodeEnum.invalid_password ||
          err.errorCode === apiErrorCodeEnum.code_mismatch ||
          err.errorCode === apiErrorCodeEnum.recently_used_password)
      ) {
        setSubmissionError(err);
        return;
      }

      rollbar.error(err as Error);
      const errorBanner = isServiceError ? generateErrorBanner(t, err.errorCode) : undefined;
      // There was an error verifying a token, it may have expired or been incorrect
      // Pass the error to the token verification page and navigate there
      history.push({
        pathname: "/verify",
        search: history.location.search,
        state: { errorBanner },
      });
    } finally {
      setSubmitting(false);
    }
  };

  const backToLogin = () => {
    history.push({
      pathname: "/login",
      search: history.location.search,
    });
  };

  const pageContent = (
    <NewPasswordEntry
      onSubmit={doNewPassword}
      showCodeInput
      submissionError={submissionError}
      clearSubmissionError={() => setSubmissionError(undefined)}
      Notice={
        <p>
          {t("LOGIN:ENTER_VER_CODE_DESC")} <span className="fw-bold">{params.email}</span>
        </p>
      }
    />
  );
  return <BasePage pageContent={pageContent} isLoading={submitting} />;
};

export default ResetPasswordPage;

function isApiError(err: unknown): err is ApiError {
  return !!(err as ApiError).errorCode
}
