import { FlashCard, FlashNamespaced, useWindowSize } from "@flashparking-inc/ux-lib-react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import FlashCentralSvg from "assets/flash-central.svg?react";
import { version } from "lib/config/pkgJson";

export type ContentWrapperProps = PropsWithChildren<{
  /** @default true */
  showSupportLink?: boolean;
}>;

/** Wraps content in a card on desktop, removes the card on mobile */
export default function ContentWrapper(props: ContentWrapperProps) {
  const { children = null, showSupportLink = true } = props;
  const { windowIsMobile } = useWindowSize();
  const { t } = useTranslation();

  const paddedDiv = (
    <div className="px-flash-300 py-flash-500">
      <div className="d-flex flex-column">
        <FlashCentralSvg />
        <main className="mt-flash-500">{children}</main>
        <div className="text-center">
          {showSupportLink && (
            <div>
              <a
                className="btn btn-link my-flash-300"
                href="https://www.flashparking.com/support-center/"
              >
                {t("LOGIN:CONTACT_SUPPORT")}
              </a>
            </div>
          )}
          <div className="flash-font-body-s text-tertiary">
            {t("COMMON:VERSION")}: <code className="text-tertiary">{version}</code>
          </div>
        </div>
      </div>
    </div>
  );

  if (windowIsMobile) {
    return <FlashNamespaced className="w-100">{paddedDiv}</FlashNamespaced>;
  }

  return <FlashCard elevation="base">{paddedDiv}</FlashCard>;
}
