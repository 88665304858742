import {
  FlashFormFieldValidationMessageProps,
  FlashFormInputTextField,
  FlashFormInputTextFieldProps,
  invokeFnSync,
} from "@flashparking-inc/ux-lib-react";
import { FocusEventHandler, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type CodeInputProps = Pick<FlashFormInputTextFieldProps, "validationMessages"> & {
  /** Prefix to use for the element ID */
  idPrefix?: string;
  /** Current value for the input */
  value?: string;
  /** Callback to invoke when value changes */
  onChange: (value?: string) => void | Promise<void>;
  /** Callback to invoke when input loses focus */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  /** 
   * Validation function to invoke for the value which will set `validationMessages`
   *   array
   */
  validate?: (value?: string) => FlashFormFieldValidationMessageProps[];
  /** Whether or not user has interacted with the input */
  codeTouched?: boolean;
  /** Apply `autofocus` for the input */
  autoFocus?: boolean;
};

/** Provides a form `input` that can be used to accept a verification code */
export default function CodeInput(props: CodeInputProps) {
  const { idPrefix, value, onChange, onBlur, validate, validationMessages, codeTouched, autoFocus } = props;
  const { t } = useTranslation();

  const mounted = useRef(false);

  useEffect(() => {
    if (!codeTouched || typeof validate !== "function" || !mounted.current) {
      return;
    }

    validate(value);
  }, [value, codeTouched]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return (
    <FlashFormInputTextField
      id={`${idPrefix}code`}
      validationMessages={[...(validationMessages || [])]}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={{ text: t("LOGIN:VER_CODE") }}
      placeholder={t("LOGIN:ENTER_CODE")}
      autoFocus={autoFocus}
    />
  );
}
