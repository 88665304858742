import { TFunction } from "i18next";
import apiErrorCodeEnum from "lib/services/apiErrorCodeEnum";

const generateErrorMessage = (
  errorCode: typeof apiErrorCodeEnum[keyof typeof apiErrorCodeEnum],
  details: any,
  message: string,
  t: TFunction
) => {
  let errorMessage = "";
  switch (errorCode) {
    case apiErrorCodeEnum.code_mismatch:
      errorMessage = t("LOGIN:ERROR_CODE_MISMATCH");
      break;
    case apiErrorCodeEnum.expired_code:
      errorMessage = t("LOGIN:ERROR_EXPIRED_CODE");
      break;
    case apiErrorCodeEnum.too_many_requests:
      errorMessage = t("LOGIN:ERROR_TOO_MANY_REQUESTS");
      break;
    case apiErrorCodeEnum.invalid_password:
      errorMessage = t("LOGIN:ERROR_INVALID_PASSWORD", { message: message });
      break;
    case apiErrorCodeEnum.recently_used_password:
      errorMessage = t("LOGIN:ERROR_RECENTLY_USED_PASSWORD");
      break;
    case apiErrorCodeEnum.not_authorized:
      errorMessage = t("LOGIN:ERROR_INCORRECT_PASS_USER");
      break;
    case apiErrorCodeEnum.password_reset_required:
      errorMessage = t("LOGIN:ERROR_PASS_RESET_REQUIRED");
      break;
    case apiErrorCodeEnum.internal_error:
      errorMessage = t("LOGIN:ERROR_INTERNAL_ERROR", { message: message });
      break;
    case apiErrorCodeEnum.user_alias_exists:
      errorMessage = details?.username
        ? t("LOGIN:ERROR_USER_ALREADY_EXISTS_W_NAME", { username: details.username })
        : details?.attribute === 'phoneNumber' 
          ? t("LOGIN:ERROR_USER_ALREADY_EXISTS_W_PHONE")
          : t("LOGIN:ERROR_USER_ALREADY_EXISTS");
      break;
    case apiErrorCodeEnum.not_found:
      errorMessage = t("LOGIN:ERROR_NOT_FOUND", { message: message });
      break;
    case apiErrorCodeEnum.user_not_confirmed:
      errorMessage = t("LOGIN:ERROR_USER_NOT_CONFIRMED", { message: message });
      break;
    case apiErrorCodeEnum.validation_failed:
      errorMessage = details.isArray()
        ? details.map((item: any) => (item.message ? item.message : "")).join("\n")
        : message;
      break;
    default:
      errorMessage = message;
      break;
  }
  return errorMessage;
};
export default generateErrorMessage;
