import { useEffect, useState } from "react";

const RESEND_DISABLED_SECONDS = 20;
const MILLISECONDS_PER_SECOND = 1000;

export function useCodeResender(options: { preventDisable?: boolean; defaultDisabled?: boolean } = {}) {
  const { preventDisable, defaultDisabled = true } = options;
  const [resendDisabled, setResendDisabled] = useState(defaultDisabled);
  const [isResending, setIsResending] = useState(false);
  const [resendCount, setResendCount] = useState(0);

  useEffect(() => {
    if (preventDisable) {
      return;
    }

    const timer = setTimeout(() => {
      setResendDisabled(false);
      clearTimeout(timer);
    }, RESEND_DISABLED_SECONDS * MILLISECONDS_PER_SECOND);

    return function () {
      clearTimeout(timer);
    };
  }, [resendCount]);

  function beginResendCountdown() {
    setResendCount((prev) => prev + 1);
  }

  return {
    resendDisabled,
    setResendDisabled,
    isResending,
    setIsResending,
    beginResendCountdown,
  };
}
